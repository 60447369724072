<template>
  <main class="ml-12">
    <div class="mt-12">
      <div class="font-normal text-sm flex flex-row gap-2">
        <img src="../../assets/svg/Path.svg" />
        <a href="#">Open Business Account</a>
      </div>
    </div>
    <div class=" bg-white mt-8 rounded-lg  overflow-hidden big-shadow">
      <div class="mt-8 mb-5 mx-20  justify-center flex flex-row items-start">
        <div class="flex-column w-8/12 mt-32">
          <div class="w-6/12 text-center ml-32 ">
            <div>
              <span class="font-semibold text-2xl mx-auto"
                >Create your pin</span
              >
            </div>
            <div class="font-normal text-xs text-primaryBlue mt-3">
              <span
                >Create a 4 digit pin code to protect your account against
                unauthorized access.</span
              >
            </div>
            <div
              class="flex flex-row gap-8 w-10/12 justify-center mx-auto mt-16 "
            >
              <trac-input></trac-input>
              <trac-input></trac-input>
              <trac-input></trac-input>
              <trac-input></trac-input>
            </div>
            <div class="text-right mr-8">
              <span class="text-primaryBlue text-xs font-normal">Clear</span>
            </div>
            <div class="w-8/12 mt-24 mx-auto">
              <trac-button class="uppercase">Submit</trac-button>
            </div>
            <div class="w-8/12 mt-6 mx-auto">
              <span class="font-bold text-xs text-primaryBlue uppercase"
                >Cancel</span
              >
            </div>
          </div>
        </div>
        <div class="flex-column w-412 mt-32 mb-20">
          <div class="grid grid-cols-2 gap-5 gap-x-12">
            <div
              v-for="(feature, i) in features"
              :key="i"
              class=" bg-accentLight px-5 pt-5 pb-10 h-40  rounded-lg  overflow-hidden flex-column"
            >
              <div>
                <img src="../../assets/svg/gift.svg" />
              </div>
              <div class="mt-8 w-10/12">
                <span class="font-normal text-base">{{ feature }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "CreatePin",
  data() {
    return {
      features: [
        "Access to businnes loans",
        "Traction loyalty Rewards.",
        "Traction loyalty Rewards.",
        "Quick bulk payouts",
        "International payments",
        " Simplified Payments & transactions",
      ],
    };
  },
};
</script>

<style scoped></style>
